import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const Verification = (props) => {
    const nav = useNavigate();

    useEffect(() => {
        // let verif = prompt("Verification system prompt");

        // if (verif === 'Belajar@2022') {

        // } else {
        //     return nav("/");
        // }
      }, [])

    return props.children;
}

export default Verification