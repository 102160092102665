import React from 'react'
import ReactTyped from 'react-typed'

const HomeAbout = () => {
  return (
    <div>
        <div className='row justify-content-center align-items-center py-5 mt-5 bg-white bg-gradient shadow' id="aboutme" style={{height: 'auto'}}>
            <div className='col-md-7 row align-items-center '>
                <div className='col-md-12 text-center'>
                    <h2 className='mb-5'>About Me</h2>
                </div>
                <div className='col-md-6'>
                    <h3 className=''>Little me</h3>
                    <p>I started make some web app when i was in university, and I am a self-taught developer.
                        <br></br>
                        I love what I am doing, and I am highly motivated to collaborate with someone.
                        If you are up into some projects, just let me know.</p>
                </div>
                <div className='col-md-6'>
                    <h3 className=''>Technologies that I use:</h3>
                    <p>
                        <span className='fw-bold'>Mobile Dev</span> 
                        <br></br>
                        <ReactTyped className="typed-text" strings={["React Native."]} typeSpeed={40} backSpeed={60} loop />
                    </p>
                    <p>
                        <span className='fw-bold'>Front End Dev</span>
                        <br></br>
                        <ReactTyped className="typed-text" strings={["HTML, CSS, Javascript, React.js, Bootstrap."]} typeSpeed={40} backSpeed={60} loop />
                    </p>
                    <p>
                        <span className='fw-bold'>Back End Dev</span> 
                        <br></br>
                        <ReactTyped className="typed-text" strings={["Laravel, Node.js, API."]} typeSpeed={40} backSpeed={60} loop />
                    </p>
                    <p>
                        <span className='fw-bold'>Database</span> 
                        <br></br>
                        <ReactTyped className="typed-text" strings={["MySQL, Postgree, Firebase."]} typeSpeed={40} backSpeed={60} loop />
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeAbout