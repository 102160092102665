import React from 'react';
import logo from './logo.svg';
import './AppDesktop.css';
import './AppMobile.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomeScreen from './screens/HomeScreen';
import Navbar from './components/Navbar';
import SettingScreen from './screens/SettingScreen';
import Verification from './middlewares/Verification';
import ProjectScreen from './screens/ProjectScreen';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={ <HomeScreen/> }></Route>
          <Route path="/setting" element={ < Verification> <SettingScreen/> </Verification>}></Route>
          <Route path="/project" element={ < Verification> <ProjectScreen/> </Verification>}></Route>
          <Route path="/project/edit/:id" element={ < Verification> <ProjectScreen/> </Verification>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
