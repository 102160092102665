import React from 'react'

const styles = {
    photo: {
        objectFit: 'cover',
        objectPosition: 'center',
        height: '23vh',
        width: '23vh',
        borderRadius: '50%',
    }
}

const Foto = () => {
  return (
    <div className='pb-3'>
        <img  style={styles.photo} width={200} height={200} src='https://rinaldycodes.github.io/my-portofolio/FOTO.jpg' alt='profile'></img>
    </div>
  )
}

export default Foto