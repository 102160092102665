import axios from 'axios';
import { React, useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import CardProject from './CardProject'

const HomeProjects = () => {
  const [projectList, setProjectList] = useState([]);

  const getProjects = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API+'project/getProjects',
        })
        
        if (res.data.code === '200') {
          setProjectList(res.data.data.projects)
        } else {
          toast.error(res.data.message)
        }
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    getProjects();
  }, [])

  return (
    <div>
        <div id="myprojects" className='row align-items-center bg-danger bg-gradient p-5 mt-5 ' style={{height: 'auto'}}>
            <div className='col'>
                <h2 className='text-center text-white mb-5'>My Projects</h2>
                <div className='row'>
                  {
                    projectList.map((project) => {
                      return ( 
                        < CardProject project_name={project.project_name} thumbnail={project.thumbnail} description={project.description} link={project.link} />
                      )
                    })
                  }
                </div>

            </div>
        </div>
    </div>
  )
}

export default HomeProjects