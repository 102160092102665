import axios from 'axios';
import { React, useEffect, useState} from 'react'
import {  toast } from 'react-toastify';

import CardProject from '../components/CardProject';
import CardProjectSuper from '../components/CardProjectSuper';
import ProjectCreate from '../components/ProjectCreate';
import ProjectFormEdit from '../components/ProjectFormEdit';

const ProjectScreen = () => {
  const [mode, setMode] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projectListInTrash, setProjectListInTrash] = useState([]);
  const [projectData, setProjectData] = useState([])

  const getProjects = async () => {
    try {
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API+'project/getProjects',
        })
        
        if (res.data.code === '200') {
          setProjectList(res.data.data.projects)
          setProjectListInTrash(res.data.data.projects_in_trash)
        } else {
          toast.error(res.data.message)
        }
    } catch (error) {
        console.log(error)
    }
  }

  const onDeleteProject = (data) => {
    if (data === true) {
      getProjects()
    }
  }

  const onEditProject = (data) => {
    if (!data) {
      setMode("")
    } else {
      setMode("edit")
      setProjectData(data);
    }
  }

  const onMode = (data) => {
      getProjects();
      setMode(data);
  }

  useEffect(() => {
      getProjects();
  }, [])
  
  const contentView = () => {
    if (mode === "edit") {
      return  < ProjectFormEdit project={projectData} onEditProject={onEditProject} />
    } else if (mode === "create") {
      return < ProjectCreate onMode={onMode} />
    } else {
      return (
        <div>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-listProject-tab" data-bs-toggle="pill" data-bs-target="#pills-listProject" type="button" role="tab" aria-controls="pills-listProject" aria-selected="true">Active Project</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-trashProject-tab" data-bs-toggle="pill" data-bs-target="#pills-trashProject" type="button" role="tab" aria-controls="pills-trashProject" aria-selected="false">Trash</button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-listProject" role="tabpanel" aria-labelledby="pills-listProject-tab">
                  <div className='row'>
                    {
                      (
                        projectList.length>0?
                        projectList.map((project) => {
                          return ( 
                            < CardProjectSuper key={project.id} onDeleteProject={onDeleteProject} onEditProject={onEditProject} project={project} project_id={project.id} project_name={project.project_name} thumbnail={project.thumbnail} description={project.description} link={project.link} />
                          )
                        })
                        :
                        <div><p className='fs-1 text-center'>No data to display</p></div>
                      )
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-trashProject" role="tabpanel" aria-labelledby="pills-trashProject-tab">
                  <div className='row'>
                    {
                      (
                        projectListInTrash.length>0?
                        projectListInTrash.map((project) => {
                          return ( 
                            < CardProjectSuper key={project.id}  onDeleteProject={onDeleteProject} onEditProject={onEditProject} project={project}  project_id={project.id} project_name={project.project_name} thumbnail={project.thumbnail} description={project.description} link={project.link} />
                          )
                        })
                        :
                        <div><p className='fs-1 text-center'>No data to display </p></div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
      )
    }
  }

  return (
    <div className='py-5 my-5'>
        <div className='container'>
            <button className='btn btn-primary mb-3' hidden={(mode === "create"?true:false)} onClick={ e => setMode('create')}>
              Create
            </button>
            {contentView()}
        </div>
    </div>
  )
}

export default ProjectScreen