import React from 'react'
import ReactTyped from 'react-typed'
import Foto from './Foto'

const HomeHeader = () => {
  return (
    <div>
        <div id="homeMain" className='row align-items-center bg-danger bg-gradient text-white py-5' style={{height: 'auto'}}>
            <div className='col text-center'>
                <Foto/>
                <h1 className='pb-3'>Rinaldy Ramadhan</h1>
                <h3>
                    <ReactTyped className="typed-text" strings={["Web Developer", "Mobile Developer"]} typeSpeed={40} backSpeed={60} loop />
                </h3>
            </div>
        </div>
    </div>
  )
}

export default HomeHeader