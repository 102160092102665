import React from 'react'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const nav = useNavigate();
  return (
    <div className='fixed-top'>
        <nav className="navbar navbar-expand-lg bg-white shadow">
            <div className="container">
                {/* <span className="navbar-brand" href="#">Navbar</span> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/#homeMain">Home</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#aboutme">About</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#myprojects">Projects</a>
                    </li>
                    <li className="nav-item">
                        <a  href="https://api.whatsapp.com/send/?phone=6288299862278" target="blank"  className="nav-link">Hire Me</a>
                    </li>
                    
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        {/* <span className="nav-link active" aria-current="page" onClick={ () => nav("/project")}>Project</span> */}
                    </li>
                </ul>
                </div>
            </div>
        </nav>

    </div>
  )
}

export default Navbar