import React, { useState } from 'react'
import ReactTyped from 'react-typed'
import parse from "html-react-parser";

import Foto from './Foto'

const SettingHeader = () => {
    const [name, setName] = useState('Rinaldy Ramadhan');
    const [workAs, setWorkAs] = useState(['Web Developer', 'Mobile Developer']);
    const [bio, setBio] = useState("I started make some web app when i was in university, and I am a self-taught developer.I love what I am doing, and I am highly motivated to collaborate with someone. If you are up into some projects, just let me know.");
    const [isFrontEndDev, setIsFrontEndDev] = useState(0);
    const [FrontEndDev, setFrontEndDev] = useState('HTML, CSS, Javascript, React.js, Bootstrap.');
    const [isBackEndDev, setIsBackEndDev] = useState(0);
    const [BackEndDev, setBackEndDev] = useState('Laravel, Node.js, API.');
    const [isMobileDev, setIsMobileDev] = useState(0);
    const [MobileDev, setMobileDev] = useState('React Native.');
    const [isDatabaseDev, setIsDatabaseDev] = useState(0);
    const [DatabaseDev, setDatabaseDev] = useState('MySQL, Postgree.sql, Firebase.');
  return (
    <div>
        <div id="homeMain" className='row align-items-center bg-danger bg-gradient text-white py-5' style={{height: 'auto'}}>
            <div className='col text-center'>
                <Foto/>
                <div className='form-group mb-3 row justify-content-center'>
                    <div className='col-md-7'>
                        <label>Ganti Foto Profile</label>
                        <input className="input form-control" type="file" placeholder="Text input" />
                    </div>
                </div>
                <div className='form-group mb-3 row justify-content-center'>
                    <div className='col-md-7'>
                        <label>Name</label>
                        <input className="input form-control" type="text" value={name} onChange={ (e) => setName(e.target.value)} />
                    </div>
                </div>
               
                <div className='form-group mb-3 row justify-content-center'>
                    <div className='col-md-7'>
                        <label>Work As</label>
                        <input className="form-control" type="text" value={workAs} onChange={ (e) => setWorkAs(e.target.value)}/>
                    </div>
                </div>

                <div className='form-group mb-3 row justify-content-center'>
                    <div className='col-md-7'>
                        <label>Bio</label>
                        <textarea className="form-control" rows={10} cols={7} type="text" value={bio} onChange={ (e) => setBio(e.target.value)} />
                    </div>
                </div>

                <div className='form-group mb-3 row justify-content-center text-start'>
                    <div className='col-md-7'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue={0} value={isFrontEndDev} onChange={e => setIsFrontEndDev((isFrontEndDev?0:1))} checked={isFrontEndDev} id="checkboxFrontEndDev" />
                            <label className="form-check-label" htmlFor="checkboxFrontEndDev">
                                Front End Dev
                            </label>
                        </div>
                        <input disabled={(isFrontEndDev?0:1)} className="form-control" type="text" value={FrontEndDev} onChange={ (e) => setFrontEndDev(e.target.value)}/>
                    </div>
                </div>
                <div className='form-group mb-3 row justify-content-center text-start'>
                    <div className='col-md-7'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue={0} value={isBackEndDev} onChange={e => setIsBackEndDev((isBackEndDev?0:1))} checked={isBackEndDev} id="checkboxFrontEndDev" />
                            <label className="form-check-label" htmlFor="checkboxFrontEndDev">
                                Back End Dev
                            </label>
                        </div>
                        <input disabled={(isBackEndDev?0:1)} className="form-control" type="text" value={BackEndDev} onChange={ (e) => setBackEndDev(e.target.value)}/>
                    </div>
                </div>
                <div className='form-group mb-3 row justify-content-center text-start'>
                    <div className='col-md-7'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue={0} value={isMobileDev} onChange={e => setIsMobileDev((isMobileDev?0:1))} checked={isMobileDev} id="checkboxFrontEndDev" />
                            <label className="form-check-label" htmlFor="checkboxFrontEndDev">
                                Mobile Dev
                            </label>
                        </div>
                        <input disabled={(isMobileDev?0:1)} className="form-control" type="text" value={MobileDev} onChange={ (e) => setMobileDev(e.target.value)}/>
                    </div>
                </div>
                <div className='form-group mb-3 row justify-content-center text-start'>
                    <div className='col-md-7'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue={0} value={isDatabaseDev} onChange={e => setIsDatabaseDev((isDatabaseDev?0:1))} checked={isDatabaseDev} id="checkboxFrontEndDev" />
                            <label className="form-check-label" htmlFor="checkboxFrontEndDev">
                                Database
                            </label>
                        </div>
                        <input disabled={(isDatabaseDev?0:1)} className="form-control" type="text" value={DatabaseDev} onChange={ (e) => setDatabaseDev(e.target.value)}/>
                    </div>
                </div>
                <div className='form-group mb-3 row justify-content-center text-start'>
                    <div className='col-md-7 row'>
                        <button className='col mx-3 btn btn-outline-light w-50'>Back</button>
                        <button className='col mx-3 btn btn-primary w-50'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SettingHeader