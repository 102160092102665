import React from 'react'

const CardProject = (props) => {
  return (
    <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
        <div className="card" style={{width: 'cover'}}>
            <img loading='lazy' src={process.env.REACT_APP_UPLOADS + props.thumbnail} className="card-img-top card-project-img" alt={'Image '+props.project_name} />
            <div className="card-body">
                <h5 className="card-title">{props.project_name}</h5>
                <p className='card-text text-muted'>{props.description}</p>
                <a href={props.link} className="btn btn-primary">View Project</a>
            </div>
        </div>
    </div>
  )
}

export default CardProject