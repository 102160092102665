import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProjectFormEdit = (props) => {
    const [projectID, setProjectID] = useState(props.project.id);
    const [projectName, setProjectName] = useState(props.project.project_name);
    const [description, setDescription] = useState(props.project.description);
    const [linkProject, setLinkProject] = useState(props.project.link);
    const [currentThumbnail, setCurrentThumbnail] = useState(props.project.thumbnail);
    const [thumbnail, setThumbnail] = useState([]);

    const handleCancel = (e) => {
        props.onEditProject(false)
    }

    const saveProject = async () => {
        const formData = new FormData();

		formData.append('id', projectID);
		formData.append('old_img', currentThumbnail);
		formData.append('thumbnail', thumbnail);
		formData.append('project_name', projectName);
		formData.append('description', description);
		formData.append('linkProject', linkProject);

        try {
            const res = await axios({
                method: 'post',
                url: process.env.REACT_APP_API+'project/saveProject',
                data: formData,
                // headers: {
                    // 'content-type': 'application/json'   
                // }
            });
            props.onEditProject(false)

            if (res.data.code === '200') {
                setProjectName('')
                setDescription('')
                setLinkProject('')
                setThumbnail('')
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div>
        {/* <button onClick={notify}>Notify!</button> */}
        <div  className='row align-items-center bg-white bg-gradient shadow p-5 mb-5' style={{height: 'auto'}}>
            <h2>Edit</h2>
            <div className='col-md-12'>
                <div className='form-group mb-3'>
                    <label>Project ID</label>
                    <input disabled={true} className='form-control'  value={projectID}  onChange={ (e) => setProjectID(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Project Name</label>
                    <input className='form-control'  value={projectName}  onChange={ (e) => setProjectName(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Description</label>
                    <input className='form-control' value={description} onChange={ e => setDescription(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Link</label>
                    <input className='form-control' value={linkProject} onChange={ e => setLinkProject(e.target.value) } />
                </div>
                <div className='form-group mb-3 row align-items-center gy-2'>
                    <label className='col-md-12'>Thumbnail</label>
                    <div className='col-lg-3'>
                        <img className='rounded' src={process.env.REACT_APP_UPLOADS+currentThumbnail} alt="preview" width={200} height={200} style={{ objectFit: 'cover'}} />
                    </div>
                    <div className='col'>
                        <input type="file" className='form-control' onChange={ e => setThumbnail(e.currentTarget.files[0]) } />
                    </div>
                </div>
                <div className='form-group mb-3'>
                    <button className='btn btn-outline-primary me-3 w-25' onClick={ handleCancel }>Cancel</button>
                    <button className='btn btn-primary w-25' onClick={saveProject}>Save</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectFormEdit