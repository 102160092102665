import React, { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProjectCreate = (props) => {
    const [projectName, setProjectName] = useState([]);
    const [description, setDescription] = useState([]);
    const [linkProject, setLinkProject] = useState([]);
    const [thumbnail, setThumbnail] = useState([]);

    const handleCancel = (e) => {
        props.onMode("");
    }

    const saveProject = async () => {
        const formData = new FormData();

		formData.append('thumbnail', thumbnail);
		formData.append('project_name', projectName);
		formData.append('description', description);
		formData.append('linkProject', linkProject);

        try {
            const res = await axios({
                method: 'post',
                url: process.env.REACT_APP_API+'project/saveProject',
                data: formData,
                // headers: {
                    // 'content-type': 'application/json'   
                // }
            });

            if (res.data.code === '200') {
                props.onMode("");
                setProjectName('')
                setDescription('')
                setLinkProject('')
                setThumbnail('')
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div>
        {/* <button onClick={notify}>Notify!</button> */}

        <div id="projectCreate" className='row align-items-center bg-white bg-gradient shadow p-5 mb-5' style={{height: 'auto'}}>
            <div className='col-md-12'>
                <div className='form-group mb-3'>
                    <label>Project Name</label>
                    <input className='form-control' value={projectName} onChange={ e => setProjectName(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Description</label>
                    <input className='form-control' value={description} onChange={ e => setDescription(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Link</label>
                    <input className='form-control' value={linkProject} onChange={ e => setLinkProject(e.target.value) } />
                </div>
                <div className='form-group mb-3'>
                    <label>Thumbnail</label>
                    <input type="file" className='form-control' onChange={ e => setThumbnail(e.currentTarget.files[0]) } />
                </div>
                <div className='form-group mb-3'>
                    <button className='btn btn-outline-primary me-3 w-25' onClick={ handleCancel }>Cancel</button>
                    <button className='btn btn-primary w-25' onClick={saveProject}>Save</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectCreate