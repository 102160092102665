import React from 'react'
import HomeAbout from '../components/HomeAbout';
import HomeHeader from '../components/HomeHeader';
import HomeProjects from '../components/HomeProjects';

const HomeScreen = () => {
  return (
    <div className='py-5 my-5'>
        <div className='container'>
            < HomeHeader />
            < HomeAbout />
            < HomeProjects />
        </div>
    </div>
  )
}

export default HomeScreen