import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'

const CardProjectSuper = (props) => {
  const editProject = (e) => {
    props.onEditProject(props.project);
  }

  const deleteProject = async (e) => {
    try {
      const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API+"project/deleteProject",
        data: {
          id: e.currentTarget.getAttribute("project_id"),
        }
      })

      if (res.data.code === '200') {
        toast.success(res.data.message);
        props.onDeleteProject(true)
      } else {
        props.onDeleteProject(false);
        toast.danger(res.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const restoreProject = async (e) => {
    try {
      const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API+"project/restoreProject",
        data: {
          id: e.currentTarget.getAttribute("project_id"),
        }
      })

      if (res.data.code === '200') {
        toast.success(res.data.message);
        props.onDeleteProject(true)
      } else {
        props.onDeleteProject(false);
        toast.danger(res.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
        <div className="card" style={{width: 'cover'}}>
            <div className='card-header'>
              <div className='row justify-content-between'>
                <div className='col'></div>
                <div className='col text-end'>
                  <div className="dropdown">
                    <span className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="material-symbols-outlined">
                      more_vert
                      </span>
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <span className="btn dropdown-item d-flex align-items-center" project_id={props.project_id} project={props.project} onClick={ editProject } >
                          <span class="material-symbols-outlined">
                          edit
                          </span> 
                          Edit
                        </span>
                      </li>
                      {
                        props.project.status === 'ACTIVE'?
                          <li>
                          <span className="btn dropdown-item d-flex align-items-center text-danger" project_id={props.project_id} onClick={ deleteProject } >
                            <span class="material-symbols-outlined">
                            delete
                            </span> 
                            Delete
                          </span>
                        </li>
                        :
                        <li>
                          <span className="btn dropdown-item d-flex align-items-center text-primary" project_id={props.project_id} onClick={ restoreProject } >
                            <span class="material-symbols-outlined">
                            restore
                            </span> 
                            Restore
                          </span>
                        </li>
                      }
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <img loading='lazy' src={process.env.REACT_APP_UPLOADS + props.thumbnail} className="card-project-img" alt={'Image '+props.project_name} />
            <div className="card-body">
                <h5 className="card-title">{props.project_name}</h5>
                <p className='card-text text-muted'>{props.description}</p>
                <a href={props.link} className="btn btn-primary">View Project</a>
            </div>
        </div>
    </div>
  )
}

export default CardProjectSuper