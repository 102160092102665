import React from 'react'

import HomeAbout from '../components/HomeAbout';
import HomeProjects from '../components/HomeProjects';
import SettingHeader from '../components/SettingHeader';

const SettingScreen = () => {
  return (
    <div className='py-5 my-5'>
        <div className='container'>
            < SettingHeader />
        </div>
    </div>
  )
}

export default SettingScreen